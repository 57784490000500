/* colors */
/*@blue:#415f8c;*/
/* PADDING */
/* TOOLBAR */
/*Toolbar colors*/
/*Toolbar button colors*/
/*Toolbar form elements*/
/*Toolbar dropdown menu*/
altai-panel-building-block-creator {
  margin: auto;
  width: 100%;
  height: 100%;
  border: 0px dotted #d7d7d7;
  position: relative;
  display: none;
}
.altai-panel.edit-mode {
  margin-bottom: 10px;
  min-height: 20px;
  border: 0px dotted transparent;
  overflow: auto;
  border: 0px dotted #d7d7d7;
}
.altai-panel.edit-mode .altai-panel-zone {
  padding: 10px;
  border: 2px dashed #d3d3d3;
  margin-bottom: 2px;
}
.altai-panel.edit-mode altai-panel-building-block-creator {
  display: block;
}
.altai-panel.edit-mode .header {
  display: inline-block;
  color: rgba(33, 33, 33, 0.9);
  font-size: 12px;
  text-align: left;
  width: 100%;
  font-family: Roboto;
  font-size: 0.8em;
  height: 25px;
  background-color: #ECECEC;
  padding: 5px;
}
.altai-panel.edit-mode .header .button {
  display: none;
  float: right;
}
.altai-panel.edit-mode .header:hover .button {
  display: block;
}
.altai-panel.edit-mode .header-inner {
  padding: 0px;
}
.altai-panel.edit-mode:hover {
  /*border: 2px dotted #e7e7e7;*/
  /*background-color:#cbcbcb;*/
}
.altai-panel a {
  color: #fff;
  text-decoration: none;
}
.altai-panel .header {
  display: none;
}
